.taskCard {
  border: none;
  height: 100%;
  background-color: #ffffff;
  padding: 10px 10px 0 10px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;


  .task-area {
    height: 100%;
    width: 140%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: auto;
  }
  textarea {
    margin: 1px;
    border: none;
    height: 100%;
    
    border-radius: 10px;
    padding: 10px;
    font-size: 1em;
    line-height: 1.5em;
    box-sizing: border-box;
    resize: none;
    display: flex;
  }

  .exerciseBar {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    margin-top: 1%;
    margin-bottom: 1%;

    .action-btn {
      height: 30px;
      // background-color: #007aff;
      color: #ffffff;
      margin-left: 10px;
      border: none;
      border-radius: 15px;
      padding: 5px 10px;
      font-size: 1em;
      transition: background-color 0.3s ease;
      text-transform: none;

      & + .action-btn {
        margin-left: 20px;
      }

      &.red {
        background-color: #ff3b30;

        &:hover {
          background-color: darken(#ff3b30, 20%);
        }
      }
    }
  }
}

.audioTaskCard {
  border: none;
  height: 116.5%;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  .task-area {
    height: calc(100% - 60px); // Adjust for padding and other elements
    width: 100%; // Widen to full width
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 20px;

    &:hover {
      overflow-y: auto;
    }

    // Scrollbar styles
    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  @media (min-width: 1200px) {
    .audioTaskCard {
      width: 25%; // On larger screens, use a percentage width
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 15px;
    font-size: 1.1em;
    line-height: 1.6em;
    box-sizing: border-box;
    resize: none;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    color: #333;

    &:focus {
      border: 1px solid #007aff;
      box-shadow: 0 0 10px rgba(0, 122, 255, 0.3);
      outline: none;
    }

    // Scrollbar styles (same as task-area)
    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .exerciseBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin-top: 10px;
    gap: 15px;

    .action-btn {
      height: 36px;
      color: #ffffff;
      border: none;
      border-radius: 18px;
      padding: 0 20px;
      font-size: 1em;
      font-weight: 500;
      transition: all 0.3s ease;
      text-transform: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      &.red {
        background-color: #ff3b30;
        &:hover { background-color: darken(#ff3b30, 10%); }
      }

      &.primary {
        background-color: #007aff;
        &:hover { background-color: darken(#007aff, 10%); }
      }

      &.error {
        background-color: #d32f2f;
        &:hover { background-color: darken(#d32f2f, 10%); }
      }
    }
  }
}