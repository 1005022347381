.input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 12px;
  width: 100%;

  #search-icon {
    color: #a0a0a0;
    margin-right: 8px;
  }

  input {
    flex-grow: 1;
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    padding: 8px 0;
    color: #333;

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .icon {
    margin-left: 8px;
    margin-right: 8px;

    &.MuiIconButton-root {
      transition: color 0.3s;

      &:hover {
        color: #1976d2;
      }
    }

    &.MuiIconButton-colorPrimary {
      color: #1976d2;
    }

    &.MuiIconButton-colorDefault {
      color: #a0a0a0;
    }
  }
}
