.about-us {

    width: 70%;
    margin: 0px auto;
    padding: 10px 30px;
    background-color: #ffffff;

    .header-container {
        padding-top: 35px;
        display: flex;
        justify-content: left;
        align-items: left;
        flex-direction: column;
        text-align: left;
        .finding-header-text{
            font-size: 40px;
            font-weight: 400;
            margin-bottom: 20px;
            color: #292929;
        }


        .header-text {
            font-size: 53px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .intro-text {
            width: 100%;
            font-size: 16px;
            color: #5a5a5a;
            padding: 0 0 25px 0;
        }
    }

    .profile-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 30px;
        grid-column-gap: 30px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto;
        height: 100%;

        .profile {
            position: relative;
            background-color: white;
            padding: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            color: rgb(86, 86, 86);
            font-weight: bold;
            font-size: 20px;
            height: 280px;
            border-radius: 10px;
            transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;

            .profile-pic {
                position: absolute;
                top: 5%;
                left: 10%;
                width: 80%;
                height: 65%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 1;
                z-index: 0;
            }

            .profile-name {
                position: absolute;
                top: 75%;
                left: 10%;
                width: 100%;
                display: flex;
                justify-content: left;
                z-index: 1;
                font-size: 18px;
                color: black;
                cursor: pointer;
                text-decoration: none;
                &:hover {
                    color: #0084ff;
                    text-decoration: underline;
                }
            }
            .profile-description {
                position: absolute;
                top: 85%;
                left: 10%;
                width: 100%;
                display: flex;
                justify-content: left;
                z-index: 1;
                font-size: 14px;
            }
        }
    }



        .division-line {
            margin-bottom: 20px;
            border: 0;
            height: 1px;
            background: #ddd;
        }

        .articles-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .article {
                display: flex;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                padding: 20px;
                align-items: flex-start;
                text-align: left;

                .article-image {
                    width: 200px;
                    height: 150px;
                    background-size: cover;
                    background-position: center;
                    border-radius: 8px;
                    margin-right: 20px;
                }

                .article-content {
                    flex: 1;

                    .article-title {
                        margin-bottom: 10px;
                        font-weight: normal;
                        text-align: left;
                        font-size: 20px;
                    }

                    .article-meta {
                        font-size: 14px;
                        color: #555;
                        margin-bottom: 10px;
                        text-align: left;

                        .article-links {
                            display: flex;
                            gap: 10px;

                            .article-link {
                                text-decoration: none;
                                color: #0073e6;
                                transition: color 0.3s;

                                &:hover {
                                    color: #005bb5;
                                }
                            }
                        }

                        .article-description {
                            margin-top: 10px;
                            font-size: 14px;
                            color: #666;
                        }

                        .article-thoughts {
                            margin-top: 10px;
                            font-size: 14px;
                            color: #666;
                            font-style: italic;
                        }
                    }
                }
            }
        
    }
}
.padding {
    width: 100%;
    height: 20vh; 
    width: 70%;
    margin: 0 auto;
    padding: 0px 30px;
    background-color: #ffffff;
  }
