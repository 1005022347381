$backgroundColor: #e0e0e0;
$errorColor: #ff3b30;
$buttonColor: #a9d5ff;
$buttonShadow: rgba(126, 175, 221, 0.883);

body {
  background-color: $backgroundColor;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/login1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .formWrapper {
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 15px;
    // box-shadow: -5px -5px 15px #c6c6c6, 10px 20px 40px #ffffff;
    max-width: 400px;
    width: 100%;
    text-align: center;
    backdrop-filter: blur(3px);
    .email-recovery {
      background-color: rgba(255, 255, 255, 0.55);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
    
      .email-recovery-input {
        width: 16px;
        height: 16px;
        margin-right: 8px; 
        margin-top: 1.2em;
      }
    
      .email-recovery-label {
        line-height: 16px; 
        color:#4a4a4a;
      }
    }

  }

  @keyframes backgroundAni {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .logo {
    font-size: 2em;
    letter-spacing: 1px;
    color: transparent;
    font-weight: 500;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% 200%;
    background-position: 0 0;
    animation: backgroundAni 3s linear infinite;
    background-color: #52ACFF;
    background-image: linear-gradient(91deg, #52ACFF 25%, #FFE32C 100%);
  }



  .loginForm {
    margin-top: 18px;
    box-sizing: border-box;
    width: 100%;
  }

  .title {
    color: #333;
    margin-bottom: 40px;
    font-size: 1.5em;
  }

  form {
    input {
      display: block;
      width: 100%;
      padding: 15px;
      margin-bottom: 20px;
      border: none;
      border-radius: 10px;
      background-color: #ffffff;
      // box-shadow: inset -4px -4px 8px #c6c6c6, inset 4px 4px 8px #ffffff;
      font-size: 1em;
      color: #333;
      box-sizing: border-box;
      border: 2px solid #ffffff;
      transition: border 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;


      &:focus {
        border: 2px solid $buttonColor;
        outline: none;
      }
    }

    .loginButton {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }

    button {
      display: block;
      width: 100%;
      padding: 15px;
      margin-bottom: 20px;
      border: none;
      border-radius: 10px;
      background-color: $buttonColor;
      // box-shadow: inset -4px -4px 8px #2d84ff;
      font-size: 1em;
      font-weight: 800;
      color: rgb(255, 255, 255);
      cursor: pointer;
      
      transition: background-color 0.2s ease, transform 0.2s ease, color 0.2s ease, box-shadow 0.2s ease, text-shadow 0.2s ease;
    
      &:hover {
        color:#52ACFF;
        transform: translate(0px, -1px);
        text-shadow: 0px 0px 5px rgba(79, 146, 255, 0.5);
        background-color: #f0f7ff;
      }
    }

    span {
      display: block;
      color: $errorColor;
      margin-bottom: 20px;
    }
  }

  button {
    display: flex;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 30px;
    background-color: $buttonColor;
    align-items: center;
    justify-content: center;

    
    // box-shadow: -4px -4px 8px #c6c6c6, 4px 4px 8px #ffffff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease, color 0.2s ease, box-shadow 0.2s ease, text-shadow 0.2s ease;
    
    &:hover {
      color:#52ACFF;
      transform: translate(0px, -1px);
      text-shadow: 0px 0px 5px rgba(79, 146, 255, 0.5);
      background-color: #f0f7ff;
    }
  }

  p {
    font-size: 0.9em;
    color: #333;

    a {
      color: #333;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
