.group-passrate {
  font-family: "Open Sans", sans-serif;
    border: none;
    background-color: white;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    height:fit-content;
  

    .group-info-test-content {
      display: flex;
      flex-direction: column;
      grid-template-columns: 30% auto;
      align-items: center;
      width: 30%;

      // .unit-test-container {
      //   display: grid;
      //   grid-template-columns: auto auto;
      //   align-items: center;
      // }
      .pass_rate {
        height: 100px;
        display: flex;
        align-items: center;

        .CircularProgressbar-text {
          font-size: 16px;
        }
      }
    }

    .user-view-container {
      width: 70%;

      .userView {
        display: flex;
        align-items: center;
        padding: 10px;

        .userViewHeader {
          display: flex;
          align-items: center;
          flex-grow: 1; // allow this container to take available space
          margin-right: 15px; // space between name and progress bars

          img {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border: 2px solid white;
            background-color: #ddddf7;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 5px;
          }
          img:hover {
            transform: scale(1.1); /* Zoom on selection */
            border-color: #007aff; /* Change border color on selection (Apple's default blue) */
            border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
            cursor: pointer;
          }
          .selected {
            transform: scale(1.1); /* Zoom on selection */
            border-color: #007aff; /* Change border color on selection (Apple's default blue) */
            border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a more pronounced shadow when selected */
          }

          .userName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(
              100% - 40px
            ); // Width minus the image and some space
          }
        }

        .progressContainer {
          flex-basis: 60%; // Here you can set a specific percentage based on your design needs

          .progressBar {
            position: relative;
            margin-top: 5px;
            width: 100%; // makes progressBar take full width of its parent: progressContainer
            height: 15px;
            background-color: #e3e3e3;
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-family: 'Arial Rounded MT', 'VAG Rounded', sans-serif;
              font-weight: 500;
              color: #ffffff;
              font-size: 16px;
            }

            .progressBarFill {
              height: 100%;
              transition: width 0.3s ease;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              border-radius: 10px;

              &.progressBarFill-red {
                background: linear-gradient(
                  to top,
                  #ff3b30,
                  lighten(#ff3b30, 15%)
                );
              }

              &.progressBarFill-yellow {
                background: linear-gradient(
                  to top,
                  #ffcc00,
                  lighten(#ffcc00, 15%)
                );
              }

              &.progressBarFill-green {
                background: linear-gradient(
                  to top,
                  #34c759,
                  lighten(#34c759, 15%)
                );
              }
            }
          }
        }
      }
    }
  }