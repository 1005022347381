.group-info-test {
    border: none;
    height: 100%;
    background-color: white;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 10px;
  }