.researchers {

    .header-container {
      padding: 40px;
      width: 70%;
      margin: 0 auto; 
      display: flex;
      flex-direction: column;
  
      .header-text {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0.5em;
        text-align: center;
      }
  
      .intro-text{
        font-size: 1rem;
        color: #666;
        text-align: left;
        margin-bottom: 20px;
      }
  
      .contact-text a {
        color: #007bff;
        text-decoration: none;
        
  
        &:hover {
          text-decoration: underline;
        }
      }
      
    }
  
    .content-container {
      padding: 20px;
      width: 70%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .section-title {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 10px;
      }
  
      .section-content {
        font-size: 1rem;
        color: #666;
        text-align: left;
      }
    }
    .contact-form {
        padding: 20px;
        width: 70%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
    
        .section-title {
          font-size: 1.5rem;
          font-weight: 500;
        }
    
        // .MuiTextField-root {
        // //   margin-bottom: 20px;
        // }
    
        .MuiButton-root {
          align-self: flex-end;
        }
      }
    .social-media-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: 2rem;
    
        .social-icon {
          font-size: 2rem;
          color: #007bff;
          text-decoration: none;
          transition: color 0.3s;
    
          &:hover {
            color: #0056b3;
          }
        }
      }
  }
  