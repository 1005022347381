.unit-test-container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  gap: 10px;
  overflow: hidden;
  height: 100%;

  .scafold {
    border: none;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #ccc;
    }
  }

  .unit-test {
    .test-bar {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 40px;
      margin-top: 10px;

      & > * {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .btn {
      width: 30%;
    }
    .form-control {
      width: 20%;
      height: 100%;

      .num-input,
      .type-select {
        height: 100% !important;

        input,
        div,
        label {
          height: 100% !important;
        }
      }

      .type-select {
        display: flex;
        align-items: center;

        .MuiSelect-select.MuiSelect-select {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }

  .topbar {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  .codeButton {
    padding: 6px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 5px;
    margin-bottom: 5px;

    &:hover {
      background-color: #0056b3;
    }
  }

  .taskCard {
    border: none;
    height: 100%;
    background-color: #ffffff;
    padding: 10px 10px 0 10px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .task-area {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
    }
    textarea {
      margin: 1px;
      border: none;
      height: 100%;
      border-radius: 10px;
      padding: 10px;
      font-size: 1em;
      line-height: 1.5em;
      box-sizing: border-box;
      resize: none;
      display: flex;
    }

    // .exerciseBar {
    //   display: flex;
    //   justify-content: flex-start;
    //   height: 40px;
    //   margin-top: 1%;
    //   margin-bottom: 1%;

    //   .action-btn {
    //     height: 30px;
    //     // background-color: #007aff;
    //     color: #ffffff;
    //     margin-left: 10px;
    //     border: none;
    //     border-radius: 15px;
    //     padding: 5px 10px;
    //     font-size: 1em;
    //     transition: background-color 0.3s ease;
    //     text-transform: none;

    //     &:hover {
    //       // background-color: darken(#007aff, 10%);
    //     }

    //     & + .action-btn {
    //       margin-left: 20px;
    //     }

    //     &.red {
    //       background-color: #ff3b30;

    //       &:hover {
    //         background-color: darken(#ff3b30, 20%);
    //       }
    //     }
    //   }
    // }
  }

  .testTable {
    /* For the table header */
    .MuiTableHead-root .MuiTableCell-root {
      padding: 6px 16px;
      font-size: 0.875rem;
    }

    /* For the table body */
    .MuiTableBody-root .MuiTableCell-root {
      padding: 3px 16px;
      font-size: 0.875rem;
    }
  }

  .textField {
    height: 10%;
  }
}
