.sample-drag-bar {
    flex-shrink: 0;
    width: 5px;
    background-color: #d1d5db;
    cursor: col-resize;
    transition: background-color 0.15s 0.15s ease-in-out;
  }
  
  .sample-drag-bar.sample-drag-bar--dragging,
  .sample-drag-bar:hover{
    background-color: #63B3ED;
  }
  
  .sample-drag-bar.sample-drag-bar--horizontal {
    height: 5px;
    width: 100%;
    cursor: row-resize;
  }