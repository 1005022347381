$BG_COLOR: #f9f9f9;
$TOPBAR_BG: #ffffff;
.home {
  background-color: #fefff7;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @import 'topbar';

  .container {
    border: 1px solid #e1e1e1;
    width: 100%;
    height: 100%;
    padding-left: 3px;
    padding-right: 3px;
    overflow-x: auto;
    display: grid;
    grid-template-columns: auto;
    position: static;

    @import 'group';

    @import 'chat';

    @import 'code';
  }

  @import 'loading';
}
