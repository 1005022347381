.code-intructor-wrapper {
    position: relative;
    overflow: auto;
    max-height: 330px;
    min-height: 330px;

  
    border: none;
    background-color: #ffffff;
    padding: 20px ;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 1; 
  
  .codeTextArea {
      z-index: 2; 
      overflow-y: auto;
      margin-bottom: 10px;
      height: 85%;
      width: 100%;
      resize: none;
      font-family: 'JetBrains Mono', monospace;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      background-color: #f8f8f8; // Light gray color
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
      &:focus {
        outline: none;
      }
    }
    }