.flex {
  display: flex;
}
.flex-row {
  //align the elements in a row with no gap to the left
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  //gap between the elements
  gap: 1rem;

  


}
.App {
  font-family: sans-serif;
  font-size: 1.5em;
}

textarea {
  font-size: 1.1em;
  width: 70%;
  display: block;
  margin: auto;
}

table {
  border: 1px solid #1c6ea4;
  background-color: #ffffff;
  margin: 1.5em auto;
  text-align: left;
  border-collapse: collapse;
}

thead {
  font-weight: bold;
  text-align: center;
  border: 2px solid #999;
}

th,
td {
  border: 1px solid #aaa;
}

.error {
  color: #922;
}

.codeOutputArea {
  height: 50vh;
  overflow-y: auto;
  width: 95%;
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 252, 252); // Light gray color
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}
.flex-column {
  flex-direction: column;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.h-screen {
  height: 100vh;
}

.bg-darker {
  background-color: #f2f2f7;
}

.bg-dark {
  background-color: #f2f2f7;
}

.font-mono {
  font-family: monospace;
}

.color-white {
  color: rgb(0, 0, 0);
}

.overflow-hidden {
  overflow: hidden;
}

.contents {
  display: grid;
  transition: filter 0.2s ease-out, background-color 0.2s ease-out;
  font-size: 16px;
  height: 85vh;
  // overflow-y: scroll;
}

.dragging {
}

.max-screen-width {
  max-width: 100vw;
}

.contents-chat {
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.div-height {
  height: 85vh;
}
.min-500 {
  min-width: 500px;
}
