$BG_COLOR: #f2f2f7; // Light gray color

.code-container {
  overflow-y: auto;
  
  .App {
    font-family: sans-serif;
    font-size: 1.5em;
  }
  
  textarea {
    font-size: 1.1em;
    width: 70%;
    display: block;
    margin: auto;
  }
  
  table {
    border: 1px solid #1c6ea4;
    background-color: #eeeeee;
    margin: 1.5em auto;
    text-align: left;
    border-collapse: collapse;
  }
  
  thead {
    font-weight: bold;
    text-align: center;
    border: 2px solid #999;
  }
  
  th,
  td {
    border: 1px solid #aaa;
  }
  
  .error {
    color: #922;
  }


  //Instructor CSS
.code {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $BG_COLOR; // Light background color
  .code-presenter-wrapper{
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  
  }
  .codeOutputWrapper{
    width: 100%;

    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;
    // text-wrap:wrap;

    z-index: 1;
  
    .codeOutputArea {
      height: 95%;
      padding: 15px;
      width: 95%;
      min-height: 350px;
      resize: none;
      font-family: 'JetBrains Mono', monospace;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      background-color: rgb(255, 252, 252); // Light gray color
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    }
  }
    .codeBar {
      padding: 10px;
      padding-left: 0;
      padding-bottom: 5px;
      width: 100%;
      background-color: $BG_COLOR;
      flex-direction: row;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      .codeButton {
        padding: 6px 16px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-right: 5px;

        &:hover {
          background-color: #0056b3;
        }
      }

      span {
        font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif; // Apple's System Font
        color: #333; // A dark color for text
        background-color: #f2f2f7; // A light grey background color found in Apple design
        padding: 8px 16px; // Padding for the text
        border-radius: 5px; // Rounded corners, which are common in Apple design
        margin-right: 10px; // A margin to create
      }

      .pass_rate {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Add a soft shadow
        border-radius: 50%; // Add rounded corners

        .CircularProgressbar-text {
          font-size: 16px;
        }
      }
    }
      .codeTextArea {
        overflow-y: auto;
        margin-bottom: 10px;
        height: 85%;
        width: 100%;
        resize: none;
        font-family: 'JetBrains Mono', monospace;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        background-color: #ffffff; // Light gray color
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
        &:focus {
          outline: none;
        }
  
        &::-webkit-scrollbar {
          width: 8px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
  
        &:hover::-webkit-scrollbar-thumb {
          background: #ccc;
        }
  
        // Firefox
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
  
        &:hover {
          scrollbar-color: #ccc transparent;
        }
  
        .CodeMirror-text pre {
          padding-left: 4ch;
        }
      }
      .add-to-presenter-button{
        width: 100%;
      }
    
    .student-info-container {
      // padding: 10px;
      background-color: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 15px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
      width: 100%;
  
      
      .studentInfo-title {
          padding: 10px;
          font-size: 2.125rem;
          font-weight: 300;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 15px;
          border-bottom: 1px solid #e1e1e1;
          padding-bottom: 10px;
          text-align: center;
      }
  
      .student-info {
        font-size: 1.2em;
        color: #333;
        font-weight: 600;
        text-align: left;
        margin-top: 10px;
      }
    }
  
    
    //

    .embeddedCodeBar {
      margin: 10px;
      padding: 20px;
      background-color: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 15px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
      display: flex;
      flex-wrap: wrap;
    
      h3 {
        font-size: 1.5em;
        margin-bottom: 15px;
        color: #333;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 10px;
        width: 100%;
      }
    
      .stats-container {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    
        .column {
          flex: 1;
          padding: 0 10px;
    
          h4 {
            font-size: 1.2em;
            color: #333;
            margin-bottom: 10px;
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 5px;
          }
        }
    
        .left-column {
          border-right: 1px solid #e1e1e1;
        }
      }
    
      p {
        font-size: 1em;
        color: #333;
        margin: 10px 0;
        line-height: 1.6;
    
        &.highlight {
          font-weight: 600;
          color: #007aff;
        }
      }
    
      .line-chart {
        width: 100vh;
        height: 300px;
        margin: 0 auto;

      }
    
      .class-stats {
        width: 100%;
    
        h4 {
          font-size: 1.2em;
          color: #333;
          margin-bottom: 10px;
          border-bottom: 1px solid #e1e1e1;
          padding-bottom: 5px;
        }
    
        .error-list {
          margin-top: 10px;
    
          p {
            color: #ff3b30;
          }
        }
      }
    }
  
  
  
  
  
    //------
  .userSwitch {
    width: 20%;
    height: 80%;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    img {
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #ddddf7;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border: 2px solid white;
      transition: transform 0.2s, border-color 0.2s; /* Smooth transition */
    }
    .selected {
      transform: scale(1.1); /* Zoom on selection */
      border-color: #007aff; /* Change border color on selection (Apple's default blue) */
      border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a more pronounced shadow when selected */
    }
  }
}


//Student CSS
.code-stud {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $BG_COLOR; // Light background color
  

  .container {
    border: none;
    height: 100%;
    width: 100%;
    background-color: $BG_COLOR;
    

    display: flex;
    overflow: visible;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* codeBar.scss */
    .codeBar {
      padding: 10px;
      padding-left: 0;
      padding-bottom: 5px;
      padding-top:0;
      width: 100%;
      background-color: $BG_COLOR;
      flex-direction: row;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      .codeButton {
        display: flex;
        align-items: center; 
        margin: 0 2px;
        padding: 10px 16px;
        background-color: #ffffff;
        color: #000000;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 16px;

        &:hover {
          background-color: #d4d4d4;
        }
      }

      span {
        font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif; // Apple's System Font
        color: #333; // A dark color for text
        background-color: #f2f2f7; // A light grey background color found in Apple design
        padding: 8px 16px; // Padding for the text
        border-radius: 5px; // Rounded corners, which are common in Apple design
        margin-right: 10px; // A margin to create
      }

      .pass_rate {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Add a soft shadow
        border-radius: 50%; // Add rounded corners

        .CircularProgressbar-text {
          font-size: 16px;
        }
      }
    }

    .full-size{
      width: 100%;
      height: 100%;
    

      .codeTextArea {
        flex: 3;
        overflow-y: auto;
        margin-bottom: 10px;
        height: 85%;
        width: 100%;
        resize: none;
        font-family: 'JetBrains Mono', monospace;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        background-color: #ffffff; // Light gray color
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
        &:focus {
          outline: none;
        }
  
        &::-webkit-scrollbar {
          width: 8px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
  
        &:hover::-webkit-scrollbar-thumb {
          background: #ccc;
        }
  
        // Firefox
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
  
        &:hover {
          scrollbar-color: #ccc transparent;
        }
      }
    }
  
    .scafold {
      flex: 1;
      width: 100%;

      .codeTestArea {
        overflow-y: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 85%;
        width: 96%;
        resize: none;
        font-family: 'JetBrains Mono', monospace;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        background-color: #ffffff; // Light gray color
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
      }
    }
  }
  .codeOutputWrapper{
    width: calc(100% - 10px);

    height: calc(80vh - 260px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
    padding: 10px 10px 0px 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 0px 5px;
    z-index: 1;
  
  .codeOutputArea {
    padding: 15px;
    height: 100%;
    width: 95%;
    resize: none;
    font-family: 'JetBrains Mono', monospace;
    font-size: 16px;
    min-height: 350px;
    border: none;
    border-radius: 5px;
    overflow-y: auto;
    background-color: rgb(255, 255, 255); // Light gray color
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
  }

  .thought {
    display: flex;
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 50px;
    min-width: 40px;
    max-width: 70%;
    min-height: 40px;
    max-height: 40vh;
    margin: 20px;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    
  }
  .speechbubble {
    font-family: sans-serif;
    background-color: #f2f2f2;
    color: #161719;
    font-size: 0.9em;
    line-height: 1.75;
    padding: 15px 25px;
    margin-bottom: 20px;
    border-radius: 10px 10px 0 10px;
    cursor: default;
    border-right: 5px solid #56b273;
    width: 80%;
    display: flex;
    flex-direction: column;
   
  }
  
}

  .userSwitch {
    width: 20%;
    height: 80%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    img {
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #ddddf7;
      height: 75px;
      width: 75px;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border: 2px solid white;
      transition: transform 0.2s, border-color 0.2s; /* Smooth transition */
    }
    .selected {
      transform: scale(1.1); /* Zoom on selection */
      border-color: #007aff; /* Change border color on selection (Apple's default blue) */
      border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a more pronounced shadow when selected */
    }
  }
}


}
