.search-list {
  .audio-groupList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - 80px); /* Limit the height of the container */
    box-sizing: content-box; /* Prevent padding affecting width calculation */
  }

  /* Custom scrollbar styling */
  .audio-groupList::-webkit-scrollbar {
    width: 8px;
  }

  .audio-groupList::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }

  .audio-groupList::-webkit-scrollbar-thumb {
    background-color: #00796b;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }

  .audio-groupList::-webkit-scrollbar-thumb:hover {
    background-color: #004d40;
  }

  .audio-groupList .group-card {
    flex: 1 1 calc(25% - 20px); /* Default to 4 cards per row */
    max-width: 250px; /* Adjust the max-width to fit your card size */
  }

  @media (max-width: 1200px) {
    .audio-groupList .group-card {
      flex: 1 1 calc(33.33% - 20px); /* 3 cards per row */
    }
  }

  @media (max-width: 900px) {
    .audio-groupList .group-card {
      flex: 1 1 calc(50% - 20px); /* 2 cards per row */
    }
  }

  @media (max-width: 600px) {
    .audio-groupList .group-card {
      flex: 1 1 calc(100% - 20px); /* 1 card per row */
    }
  }
}

.audio-search-bar {
  margin-bottom: 20px; /* Add margin below the search bar */
  padding: 10px 20px; /* Add padding inside the search bar */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-right: 10px;

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .icon {
    margin-left: 10px;
    color: #00796b;

    &:hover {
      color: #004d40;
    }
  }
}
