.topic-list-container {
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px;
  background: #fefefe;
}

.title {
  font-size: 1.8em;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #333;
  text-align: center;
}

.topic-list {
  list-style: none;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  width: 80%;

  /* Custom scrollbar styles */
  // &::-webkit-scrollbar {
  //   width: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: #f0f0f0;
  //   border-radius: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #007acc;
  //   border-radius: 4px;
  //   border: 2px solid #f0f0f0;
  // }

  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topic-item {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 10px;
  background: #dcedf7;
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s, background 0.3s, color 0.3s;
  cursor: pointer;
  color: #007acc;

  &:hover {
    transform: translateY(-5px);
    background: #c4e1f5;
    color: #005f99;
  }
}

.dialog-list {
  list-style: none;
  padding: 10px;
  margin-top: 10px;
  background: #e6f7ff;
  border-radius: 10px; /* Rounded corners */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;

  &.expanded {
    max-height: 300px; /* Set a reasonable max-height */
    opacity: 1;
    overflow-y: auto; /* Add vertical scroll */
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  }

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e6f7ff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007acc;
    border-radius: 4px;
    border: 2px solid #e6f7ff;
  }
}

.dialog-item {
  margin: 5px 0;
  padding: 8px;
  background: #d4edfa;
  border-radius: 5px; /* Rounded corners */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.dialog-content {
  font-size: 1em;
  color: #333;
}

.dialog-sender {
  font-size: 0.9em;
  color: #007acc;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007acc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  height: 50px;
  margin: 10px;

  &:hover {
    background-color: #005f99;
  }
}
