


    .userSwitch {
        background-color: #ffffff;
    padding: 10px 10px 0 10px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
        width: 100%;
        height: auto;
        display: flex;
        overflow: scroll;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        img {
          margin-top: 5px;
          margin-bottom: 5px;
          background-color: #ddddf7;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          border: 2px solid white;
          transition: transform 0.2s, border-color 0.2s; /* Smooth transition */
        }
        img:hover {
          transform: scale(1.1); /* Zoom on selection */
          border-color: #007aff; /* Change border color on selection (Apple's default blue) */
          border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a more pronounced shadow when selected */
          cursor: pointer;
        }
        .selected {
          transform: scale(1.1); /* Zoom on selection */
          border-color: #007aff; /* Change border color on selection (Apple's default blue) */
          border: 2px solid #8e8e93; /* Change border to a dark grey color when selected */
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a more pronounced shadow when selected */
        }
      }

