.code-mirror-wrapper {
  position: relative;
  // background-color: #f0f0f0; /* Light grey background */
  max-height: 250px;
  min-height: 250px;
  overflow: auto;

  border: none;
  background-color: #ffffff;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0px 5px;
  z-index: 1; 

.codeTextArea {
    z-index: 2; 
    overflow-y: auto;
    margin-bottom: 10px;
    height: 85%;
    width: 100%;
    resize: none;
    font-family: 'JetBrains Mono', monospace;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #f2f2f2; // Light gray color
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar { 
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #ccc;
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
      scrollbar-color: #ccc transparent;
    }

    .CodeMirror-text pre {
      padding-left: 4ch;
    }
  }
.blockly-editor {
  height: 50vh;
}
&:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff; /* Light grey background */
  z-index: 0; /* Ensure it is behind CodeMirror */
}
}
.code-editor-style {
  background-color: #eeeeee; /* A dark background similar to many code editors */
  color: #e6532e; /* Light text color */
  font-family: 'Fira Code', 'Consolas', 'Courier New', monospace; /* Monospace font for code */
  font-size: 16px;
  padding: 10px; 
  border-radius: 4px;
  font-weight: 500;
  overflow-x: auto; /* Handle horizontal scrolling if the code is long */
  white-space: pre-wrap; /* Maintain formatting, but allow line breaks */
}
.dialog-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.close-icon {
  cursor: pointer;
}

.list-item-button {
  justify-content: space-between;
  display: flex;
}

.list-item-icon {
  min-width: auto;
  margin-left: auto;
}
.list-item-text {
  flex-grow: 1;
}

.check-icon {
  margin-left: 30px;

  &.hovered {
    color: #a0a0a0;
  }

  &.default {
    color: white;
  }
}
