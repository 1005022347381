.main-container {
  display: flex;
  min-height: 100vh; 
  width: 100%;

  .left-panel {
    width: 300px; 
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
  }

  .central-panel {
    flex-grow: 1; 
    padding: 20px; 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}

// AudioChat styles
.audio-chat-container {
  display: flex;
  flex-grow: 1; 
  width: 100%;
  height: 115vh; 
  overflow-y: auto; 

  .chat {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0; // Allow shrinking
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .group-name {
      flex: 0 0 auto;
      font-size: 24px;
      font-weight: bold;
      color: #4a90e2;
      text-align: center;
      margin-bottom: 16px;
      border-bottom: 2px solid #4a90e2;

      .leave-group-button {
        margin: 0 auto;
        color: #4a90e2;
      }
    }

    .chat-history {
      flex: 1;
      overflow-y: auto;
      padding: 16px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 16px;
      min-height: 0; // Allow shrinking
    }

    .message-form {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      padding: 15px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      gap: 15px;

      .message-input {
        flex-grow: 1;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 10px 15px;
        font-size: 16px;
        resize: none;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #00796b;
          outline: none;
        }
      }

      .send-button {
        background-color: #00796b;
        color: #ffffff;
        border: none;
        padding: 10px 25px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          background-color: #004d40;
          transform: scale(1.05);
        }
      }
    }
  }

  .timer-panel {
    width: 750px;
    padding: 20px;
    background-color: #f5f5f5;
    border-left: 1px solid #e0e0e0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: bold;
      color: #333;
      text-align: center;
    }
  
    .scenario {
      margin-top: 30px;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      color: #333;
    }
  
    .dropdowns-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  
    .dropdowns {
      flex-grow: 1;
      overflow-y: auto;
      margin-top: 30px;
      padding-right: 10px;
      padding-bottom: 20px; // Add some space at the bottom
    }
  
    .timer-item {
      margin-bottom: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      overflow: hidden;
  
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
      }
  
      &.active {
        border: 2px solid #4a90e2;
      }
  
      .timer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        cursor: pointer;
      }
  
      .timer-title {
        font-weight: 600;
        font-size: 16px;
        color: #333;
      }
  
      .timer-time {
        font-weight: bold;
        font-size: 16px;
        color: #4a90e2;
      }
  
      .timer-description {
        padding: 15px;
        font-size: 14px;
        color: #666;
        background-color: #f9f9f9;
        border-top: 1px solid #e0e0e0;
      }
  
      .expand-button {
        width: 100%;
        border: none;
        background-color: #e6f0ff;
        color: #4a90e2;
        padding: 5px;
        cursor: pointer;
        font-size: 12px;
      }
    }
  
    .total-time {
      flex-shrink: 0;
      margin-top: 20px;
      margin-bottom: 270px;
      background-color: #e6f0ff;
      border: 2px solid #4a90e2;
      border-radius: 8px;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
  
      .timer-title, .timer-time {
        font-size: 18px;
        color: #4a90e2;
      }
  
      .timer-time {
        margin-left: 15px;
      }
    }
  }
}

@media (max-height: 600px) {
  .audio-chat-container {
    flex-direction: column;
  }

  .timer-panel {
    width: 100%;
    height: auto;
    border-left: none;
    border-top: 1px solid #e0e0e0;
  }
}

.popup-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      margin-top: 0;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      margin: 16px 0;
    }

    button {
      background-color: #4a90e2;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        background-color: #357abd;
      }
    }
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;

  h2 {
    margin-top: 0;
    color: #333;
  }

  p {
    margin-bottom: 20px;
    color: #666;
  }

  .message-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .send-button {
      align-self: flex-start;
      background-color: #4a90e2;
      color: white;
      border: none;
      padding: 8px 15px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #357abd;
      }
    }
  }

  .close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      background-color: #d32f2f;
    }
  }
}