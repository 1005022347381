.room-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #f0f0f5;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  height: 100vh;

  .guidance-image {
    margin-bottom: 20px;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }

  .input-wrapper {
    position: relative;
    width: calc(100% - 40px);
    max-width: 400px;
    margin-bottom: 15px;
    background: transparent;
    box-shadow: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .room-input {
    width: 100%;
    padding: 15px;
    padding-right: 50px;
    border: none;
    font-size: 1.2em;
    border-radius: 10px;
    box-sizing: border-box;
    color: #333;
    background: #fff;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;

    &:focus {
      background: #e0e0e5;
    }

    &::placeholder {
      color: #999;
    }
  }

  .info-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }

  .submit-button {
    width: calc(100% - 40px);
    max-width: 400px;
    padding: 15px;
    border: none;
    font-size: 1.2em;
    border-radius: 10px;
    color: #fff;
    background: #6ad2f5;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      background: #65b2d1;
      transform: translateY(-2px);
    }

    &:active {
      background: #6495ed;
      transform: translateY(1px);
    }
  }
}
