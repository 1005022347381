.scafold {
    border: none;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #ccc;
    }

   
      .test-bar {
        // border: 1px solid #e1e1e1;
        box-sizing: border-box;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 10px;
  
        & > * {
          margin-right: 5px;
  
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .btn {
        width: 30%;
      }
      .form-control {
        width: 20%;
        height: 100%;
  
        .num-input,
        .type-select {
          height: 100% !important;
  
          input,
          div,
          label {
            height: 100% !important;
          }
        }
  
        .type-select {
          display: flex;
          align-items: center;
  
          .MuiSelect-select.MuiSelect-select {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
      }
    }
  


.textField{
  background-color: #f3f3f3;
  border-radius: 10px;
  margin: 10px;
  
  .MuiInput-underline:before {
    border-bottom: none; 
  }
  .MuiInput-underline:after {
    transition: none; 
    border-bottom: none; 
    
  }

  &:focus, &:hover , &:active{
    border: 1px solid #007bff;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none; 
    transition: none; 
  }
}
