.chat {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  background-color: #ffffff;
  overflow-y: visible;
  height: 80%;

  .controlBar {
    display: flex;
    // justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;

    img {
      margin: 0 10px;
      cursor: pointer;
      height: 30px;
      width: 30px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .chat-history {
    flex: 1;
    overflow-y: auto;
    // padding: 20px;
    margin: 10px;
    border-radius: 12px;
    background-color: #f2f2f2;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    //height: calc(100% - 160px);
    overflow-x: hidden;
    height: 30vh;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #ccc;
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
      scrollbar-color: #ccc transparent;
    }

    .message {
      display: flex;
      gap: 20px;
      padding: 10px 20px;
      justify-content: right;
      .emoji{
        margin-right: 4px;
        margin-top: 2px;
      }

      &:hover {
        background-color: #e5e5e5; // Change background color when hovering
      }

      .messageInfo {
        max-width: 15%;
        min-width: 15%;
        display: flex;
        flex-direction: column;
        color: gray;
        font-weight: 300;
        align-items: center;

        .info-user {
          color: #333;
          font-weight: bold;
          text-align: center;
        }

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); // Add shadow
          border: 2px solid white; // Add border
        }
        span {
          font-size: xx-small;
        }
      }

      .messageContent {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;


        pre {
          margin-left: -10px;
          margin-top: 15px;
          max-width: 100%;
          background-color: white;
          padding: 10px 20px;
          border-radius: 0px 10px 10px 10px;
          overflow-wrap: break-word; /* add this line */
          word-break: break-word; /* and this line */
          font-size: small;
          white-space: pre-wrap;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
      .messageContentRight {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        pre {
          margin-right: -10px;
          margin-top: 15px;
          max-width: 100%;
          background-color: white;
          padding: 10px 20px;
          border-radius: 10px 0px 10px 10px;
          overflow-wrap: break-word; /* add this line */
          word-break: break-word; /* and this line */
          font-size: small;
          white-space: pre-wrap;
          font-family: Arial, Helvetica, sans-serif;
        }
      }

      .dm {
        font-size: 0.8em;
        color: #0070c9;
      }

      // &.owner {
      //   flex-direction: row-reverse;

      //   .messageContent {
      //     align-items: flex-end;
      //     p {
      //       background-color: #8da4f1;
      //       color: white;
      //       border-radius: 10px 0px 10px 10px;
      //     }
      //   }
      // }
    }

    .message.selected {
      background-color: #e5e5e5; /* an example of apple-style selection color */
    }
  }

  .message-form {
    display: flex;
    margin: 10px;

    .message-input {
      white-space: pre-wrap;
      flex: 1;
      margin-right: 10px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #ddd;
      font-size: 16px;
      // width: fit-content;
      resize: none;
      &:focus {
        outline: none;
      }
    }

    .send-button {
      padding: 10px 20px;
      background: #007bff;
      color: #fff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background: #0056b3;
      }
    }
  }
}
