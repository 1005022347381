/* AudioGroupView.css */

.group-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  color: #333333;
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.group-card:hover {
  transform: translateY(-5px);
  background-color: #f0f0f0;
}

.group-card.selected {
  background-color: #e0f7fa; /* Light cyan background for selected card */
  border: 2px solid #00796b; /* Teal border for selected card */
}

.group-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 0.9em;
  margin: 0;
  text-align: center;
  color: #00796b;
  width: 40%;
}

.topics-container {
  // display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 80%;
  overflow: hidden;
  max-height: 100%;
  overflow-y: auto;
}

.topics-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.topic-tag {
  background: rgba(0, 150, 136, 0.2);
  border-radius: 5px;
  padding: 3px 8px;
  margin: 2px 0;
  font-size: 0.7em;
  text-align: center;
  color: #004d40;
  transition: background 0.2s ease;
}

.topic-tag:hover {
  background: rgba(0, 150, 136, 0.3);
}
