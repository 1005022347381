.custom-prompts {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  
    .prompt-item {
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      background-color: #fafafa;

  
      input[type="text"],
      textarea {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 4px;
        font-size: 16px;
  
        &:focus {
          border-color: #1876d2;
          outline: none;
        }
      }
  
      textarea {
        resize: none;
      }
    }
  
    button {
      background-color: #1876d2;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      margin: 10px;
  
      &:hover {
        background-color: #1876d2;
      }
    }
  }
  