.notification-wrapper {
    position: fixed; /* Make it a floating window */
    top: 10%; /* Position it near the bottom of the screen */
    right: 20px; /* Position it near the right edge of the screen */
    width: 30%; /* Adjust the width as needed */
    max-height: 400px;
    z-index: 1000; /* Ensures it appears above other elements */
    margin: 0; /* No margin needed for floating elements */
    border-radius: 8px; /* Rounded corners for a polished look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow to indicate it's floating */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0; /* Start with opacity 0, we’ll add opacity animation */
    transform: translateY(20px); /* Slight offset for animation */

    &.show {
        opacity: 1; /* Fully visible when active */
        transform: translateY(0); /* Slide in to position */
    }
}
.notification-button {
    width: 200px; 
    font-size: 14px; 
    font-weight: 600;
    height: 24px;
    border-radius: 5px;
    margin: 2px;

    border:#1976d2 1px solid;
    color:#1976d2;
}


.minimize-button {
    background: none;
    border: none;
    color: #1976d2;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 4px 8px;
    margin-left: auto;
    font-weight: 500;

    &:hover {
        color: white;
        background-color: #1976d2;
        border-radius: 8px;
    }
}

.notification-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    max-width: 8px;
    max-height: 8px;
    min-width: 8px;
    min-height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 8px;
}

.notification-container {
    background-color: #fff;
    max-height: 300px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    overflow-y: auto;
    
    .notification-header{
        display: flex;
        align-items: center;
        
        p{
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.87);
            margin: 8px 0;
            font-weight: 700;
        }
    }

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
    }
}

.notification-empty {
    font-size: 1rem;
    color: rgb(105, 105, 105);
    text-align: left;
    margin-top: 5px;
    margin-bottom: 15px;
    align-items: center;

}

.notification-preview {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // gap: 16px;

    span {
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.87);
        font-size: 1rem;
        line-height: 1.5;
        flex: 1;
    }

    .expand-button{
        background: none;
        border: none;
        color: #1976d2;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 16px;
        transition: all 0.3s ease;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
            color: white;
            background-color: #1976d2;
        }
    }

    // Red dot for unread notification
    
}

.notification-full {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.6;
    padding: 8px 0;


}

.rating-section {
    margin-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 16px;

    p {
        font-size: 0.95rem;
        color: rgba(0, 0, 0, 0.87);
        margin: 8px 0;
    }

    .thumb-buttons {
        display: flex;
        gap: 8px;
        margin: 12px 0;
        
    }

    .submit-button,
    .close-button {
        background: none;
        border: none;
        color: #1976d2;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 16px;
        transition: all 0.3s ease;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
            color: white;
            background-color: #1976d2;
        }
    }

    .close-button {
        
        &:hover {

            background-color: #333;
        }
    }

    
}
.rating-message {
    font-size: 0.85rem;
    color: #4caf50; 
    margin-top: 8px;
}
.submit-button,
.close-button {
    background: none;
    border: none;
    color: #1976d2;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.3s ease;
    border-radius: 10px;
    margin-top: 10px;

    &:hover {
        color: white;
        background-color: #1976d2;
    }
}
